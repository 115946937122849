export const sellerLeadIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "attributes.address.attributes.firstName",
    title: "Vorname",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    type: "Def",
    key: "attributes.address.attributes.lastName",
    title: "Nachname",
    sortable: true,
    options: {
      hrefFunction: (data: any) => `/leads/${data.item.id}`
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "householdCount",
    title: "Haushalte",
    sortable: true,
    options: { contentFunction: (data:any) => data.item.attributes.households.length }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "attributes.createdAt",
    title: "Erstellt am",
    sortable: true,
    options: { type: 'date' }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "attributes.proto_leadsource.attributes.displayName",
    title: "Leadquelle",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "attributes.archived",
    title: "Archiviert",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    type: "Def",
    key: "attributes.reclaimed",
    title: "Reklamiert",
    sortable: true,
  },
]

export const internalLeadIndexColumns = [
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.address.attributes.firstName",
    title: "Vorname",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/LinkCell.vue"),
    key: "attributes.address.attributes.lastName",
    title: "Nachname",
    sortable: true,
    options: {
      contentFunction: (data: any) => {
        // const fn = data.item.attributes.address.attributes.firstName
        // const ln = data.item.attributes.address.attributes.lastName
        // return `${fn} ${ln}`.trim()
        return data.item.attributes.address.attributes.lastName || data.item.id
      },
      hrefFunction: (data: any) => `/leads/${data.item.id}`,
      sortFunction: (firstEl: string, secondEl: string) => firstEl.localeCompare(secondEl)
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.households",
    title: "Haushalte",
    sortable: true,
    options: {
      contentFunction: (data: any) => {
        return data.item.attributes.households.length
      },
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.createdAt",
    title: "Erstellt am",
    sortable: true,
    options: {
      type: 'date'
    }
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.proto_leadsource.attributes.name",
    title: "Leadquelle",
    sortable: true,
  },
  {
    component: () => import("@/components/shared/table/cells/ValueCell.vue"),
    key: "attributes.seller.attributes.firstName",
    title: "KD",
    sortable: true,
  },
]