import { CollectionResourceDoc } from '@/models/jsonapi'
import { Leadsource } from '@/models/leadsources'

export const internalEditLeadSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/lead-edit-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        sellerId: { type: "string", title: "Seller Id" },
        // notes: { type: "string", title: "Notes" },
        cost: { type: "number", title: "Kosten", default: "0" },
        price: { type: "number", title: "Preis", default: "30" },
        protoLeadsourceId: { type: "string", title: "Leadquelle" },
        archived: { type: "boolean", title: "Archiviert" },
        reclaimed: { type: "boolean", title: "Reklamiert" },
        reclaimReason: { type: "string", title: "Reklamationsgrund" },
        reclaimConfirmed: { type: "boolean", title: "Reklammation bestätigt" },
      }
    }
  }
}

export const newLeadSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/new-lead-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        address: {
          type: "object",
          title: "Address",
          properties: {
            gender: { type: "string", title: "Geschlecht", enum: ['f', 'm', 'd'] },
            firstName: { type: "string" },
            lastName: { type: "string" },
            street: { type: "string" },
            houseNumber: { type: "string" },
            additional: { type: "string" },
            zipCode: { type: "string" },
            city: { type: "string" },
            country: { type: "string" },
            phones: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: { type: "string" },
                  number: { type: "string" },
                  default: { type: "boolean" }
                }
              }
            },
            emails: {
              type: "array",
              items: {
                type: "object",
                properties: {
                  name: { type: "string" },
                  email: { type: "string" },
                  default: { type: "boolean" }
                }
              }
            }
          }
        },
        notes: { type: "string", title: "Notes" },
        cost: { type: "number", title: "Kosten", default: "0" },
        price: { type: "number", title: "Preis", default: "30" },
        protoLeadsourceId: { type: "string", title: "Leadquelle" },
        assignPooled: { type: "boolean", title: "erfüllt Lead Bestellung" }
      }
    }
  }
}

export const newLeadUISchema = (leadsources: CollectionResourceDoc<string, Leadsource>) => {
  return {
    $schema: "https://json-schema.org/draft/2019-09/schema",
    $id: "https://crm.pflegehilfe-senioren.de/new-lead-view.json",
    $vocabulary: {
      "https://json-schema.org/draft/2019-09/vocab/core": true,
      "https://crm.pflegehilfe-senioren.de/ui-vocab": true
    },
    "ui:type": "form",
    "ui:widget": "b-row",
    type: "object",
    properties: {
      attributes: {
        type: "object",
        $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes",
        "ui:type": "group",
        "ui:label": "",
        properties: {
          address: {
            "ui:label": "Address",
            "ui:type": "group",
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address",
            properties: {
              gender: {
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/gender",
                "ui:label": "",
                "ui:widget": "b-form-radio-group",
                "ui:classNames": ["col-12"],
                "ui:options": {
                  options: [
                    {value: "m", text: "Mann"},
                    {value: "f", text: "Frau"},
                    // {value: "d", text: "Divers"},
                  ]
                },
              },
              firstName: {
                "ui:label": "Vorname",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/firstName",
                "ui:classNames": ["col-6"]
              },
              lastName: {
                "ui:label": "Nachname",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/lastName",
                "ui:classNames": ["col-6"]
              },
              steet: {
                "ui:label": "Strasse",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/street",
                "ui:classNames": ["col-12", "col-md-9", "col-lg-10"]
              },
              houseNumber: {
                "ui:label": "Hausnummer",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/houseNumber",
                "ui:classNames": ["col-12", "col-md-3", "col-lg-2"]
              },
              additional: {
                "ui:label": "Addresszusatz",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/additional",
                "ui:classNames": ["col-12"]
              },
              zipCode: {
                "ui:label": "PLZ",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/zipCode",
                "ui:classNames": ["col-12", "col-md-4"]
              },
              city: {
                "ui:label": "Ort",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/city",
                "ui:classNames": ["col-12", "col-md-4"]
              },
              country: {
                "ui:label": "Land",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/country",
                "ui:classNames": ["col-12", "col-md-4"]
              },
              phones: {
                type: "array",
                "ui:type": "collection",
                "ui:label": "Telefonnummer",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/phones",
                items: {
                  type: "object",
                  "ui:type": "group",
                  $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/phones/items",
                  properties: {
                    number: {
                      "ui:label": "Telefonnummber",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/phones/items/properties/number",
                      "ui:classNames": ["col-12", "col-md-4"]
                    },
                    name: {
                      "ui:label": "Bezeichnung",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/phones/items/properties/name",
                      "ui:classNames": ["col-12", "col-md-5"]
                    },
                    default: {
                      "ui:label": "Default",
                      "ui:type": "checkbox",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/phones/items/properties/default",
                      "ui:classNames": ["col-12", "col-md-3"]
                    }
                  }
                }
              },
              emails: {
                type: "array",
                "ui:type": "collection",
                "ui:label": "E-Mail",
                $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/emails",
                items: {
                  type: "object",
                  "ui:type": "group",
                  $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/emails/items",
                  properties: {
                    email: {
                      "ui:label": "E-Mail-Addresse",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/emails/items/properties/email",
                      "ui:classNames": ["col-12", "col-md-4"]
                    },
                    name: {
                      "ui:label": "Bezeichnung",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/emails/items/properties/name",
                      "ui:classNames": ["col-12", "col-md-5"]
                    },
                    default: {
                      "ui:label": "Default",
                      "ui:type": "checkbox",
                      $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/address/properties/emails/items/properties/default",
                      "ui:classNames": ["col-12", "col-md-3"]
                    }
                  }
                }
              }
            }
          },
          notes: {
            "ui:label": "Notes",
            "ui:widget": "b-textarea",
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/notes"
          },
          protoLeadsourceId: {
            "ui:type": "collection",
            "ui:widget": "v-select",
            "ui:label": "Leadquelle",
            "ui:options": {
              options: leadsources ?? [],
              reduce: leadsource => leadsource.id,
              getOptionLabel: opt => opt.attributes.displayName
            },
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/protoLeadsourceId",
            items: {
              type: "string",
              $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/protoLeadsourceId/items"
            }
          },
          cost: {
            "ui:label": "Kosten",
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/cost",
            "ui:options": {
              type: "number"
            }
          },
          price: {
            "ui:label": "Preis",
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/price",
            "ui:options": {
              type: "number"
            }
          },
          assignPooled: {
            "ui:label": "erfüllt Lead Bestellung",
            "ui:type": "checkbox",
            $ref: "https://crm.pflegehilfe-senioren.de/new-lead-model.json#/properties/attributes/properties/assignPooled",
          },
        }
      }
    }
  }
}
